//USERS AND AUTH
export const SET_LOADING = 'SET_LOADING'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const USER_LOADED = 'USER_LOADED'
export const UPDATE_USER_SUCCESS = 'USER-UPDATED'
export const UPDATE_USER_FAIL = 'UPDATE_ERROR'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED'
export const REQUEST_PASSWORD_RESET_SUCCESS = 'REQUEST_PASSWORD_RESET'
export const REQUEST_PASSWORD_RESET_FAIL = 'REQUEST_PASSWORD_RESET_FAIL'
export const PASSWORD_RESET_TOKEN_INVALID = 'PASSWORD_RESET_TOKEN_INVALID'
export const PASSWORD_RESET_TOKEN_VALID = 'PASSWORD_RESET_TOKEN_VALID'
export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD'
export const RESET_USER_PASSWORD_FAIL = 'RESET_USER_PASSWORD_FAIL'
export const OPEN_ACCOUNT_DELETE_MODAL = 'OPEN_ACCOUNT_DELETE_MODAL'
export const CLOSE_ACCOUNT_DELETE_MODAL = 'CLOSE_ACCOUNT_DELETE_MODAL'

//ALERTS
export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'

// PAGES
export const SET_SAVING = 'SET_SAVING'
export const SAVE_PAGE = 'SAVE_PAGE'
export const UPDATE_PAGE = 'UPDATE_PAGE'
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const SET_PAGE_URL = 'SET_PAGE_URL'
export const SET_PAGE_BLOB = 'SET_PAGE_BLOB'
export const SET_TAG_LIST = 'SET_TAG_LIST'
export const DELETE_PAGE = 'DELETE_PAGE'
export const CLOSE_PAGE_EDIT_MODAL = 'CLOSE_PAGE_EDIT_MODAL'
export const OPEN_PAGE_EDIT_MODAL = 'OPEN_PAGE_EDIT_MODAL'
export const CLOSE_PAGE_DELETE_MODAL = 'CLOSE_PAGE_DELETE_MODAL'
export const OPEN_PAGE_DELETE_MODAL = 'OPEN_PAGE_DELETE_MODAL'
export const GALLERY_LOADED = 'GALLERY_LOADED'
export const GALLERY_ERROR = 'GALLERY_ERROR'
export const FILTER_PAGES = 'FILTER_PAGES'
export const CLEAR_FILTER = 'CLEAR_FILTER'
export const CLEAR_PAGES = 'CLEAR_PAGES'
export const DELETE_ALL_PAGES = 'DELETE_ALL_PAGES'

//BOOKS
export const BOOKS_LOADED = 'BOOKS_LOADED'
export const BOOKS_ERROR = 'BOOKS_ERROR'
export const UPDATE_PAGE_LIST = 'UPDATE_PAGE_LIST'
export const UPDATE_COVER_PAGE = 'UPDATE_COVER_PAGE'
export const SET_PAGE_INDEX = 'SET_PAGE_INDEX'
export const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE'
export const SET_CURRENT_BOOK = 'SET_CURRENT_BOOK'
export const CLEAR_CURRENT_BOOK = 'CLEAR_CURRENT_BOOK'
export const DELETE_BOOK = 'DELETE_BOOK'
export const CLOSE_BOOK_EDIT_MODAL = 'CLOSE_BOOK_EDIT_MODAL'
export const OPEN_BOOK_EDIT_MODAL = 'OPEN_BOOK_EDIT_MODAL'
export const CLOSE_BOOK_DELETE_MODAL = 'CLOSE_BOOK_DELETE_MODAL'
export const OPEN_BOOK_DELETE_MODAL = 'OPEN_BOOK_DELETE_MODAL'
export const CLEAR_BOOKS = 'CLEAR_BOOKS'
export const DELETE_ALL_BOOKS = 'DELETE_ALL_BOOKS'
export const PRINT_PDF = 'PRINT_PDF'

//STRIPE
export const CREATE_STRIPE_CUSTOMER = 'CREATE_STRIPE_CUSTOMER'
export const CREATE_STRIPE_SUBSCRIPTION = 'CREATE_STRIPE_SUBSCRIPTION'
export const STRIPE_CUSTOMER_LOADED = 'STRIPE_CUSTOMER_LOADED'
export const STRIPE_SUBSCRIPTION_LOADED = 'STRIPE_SUBSCRIPTION_LOADED'
export const STRIPE_CARD_LOADED = 'STRIPE_CARD_LOADED'
export const CLEAR_STRIPE_DATA = 'CLEAR_STRIPE_DATA'
export const UPDATE_STRIPE_CUSTOMER = 'UPDATE_STRIPE_CUSTOMER'

//STATS
export const STATS_LOADED = 'STATS_LOADED'
export const CLEAR_STATS = 'CLEAR_STATS'
